<template>
  <div id="profile">
    <Heading :head="heading" :subHead="subHeading" :color="color"/>
    <div class="container">
      <img class="mainImg" src="../assets/me.jpg" alt="A picture of me" />
      <div class="text-container">
        <h2>Who am I</h2>
        <p>
          I am a third-year student at Metropolia University of Applied Sciences with software developing as my major.
          I am aiming to become a software developer in the future. I like web development, but other things, like game
          development and making desktop apps also interest me. I am optimistic and don't give up easily. Teamwork isn't
          an unknown term to me, since I have been working in teams a lot in prior work places and projects. Some of the
          things that interest me are:
        </p>
        <div class="interests">
          <figure>
            <img src="../assets/gameInterest.svg" alt="Gaming image" />
            <figcaption>Gaming</figcaption>
          </figure>
          <figure>
            <img src="../assets/movieInterest.svg" alt="Movie image" />
            <figcaption>Movies</figcaption>
          </figure>
          <figure>
            <img src="../assets/spaceInterest.svg" alt="Space image" />
            <figcaption>Space</figcaption>
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Heading";

export default {
  name: "Profile",
  components: {
    Heading
  },
  data() {
    return {
      heading: "00: Profile",
      subHeading: "Programmer / Student",
      color: "black"
    }
  }
}
</script>

<style scoped>
#profile {
  background-color: #FAFAF5;
  background-image: url("../assets/endless-clouds-white.svg");
  background-size: cover;
  background-position: center;
  color: #080808;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 16px;
}
.container{
  display: flex;
  flex-direction: row;

  max-width: 1200px;
  line-height: 1.6;
  margin: 40px;
}
.text-container{
  display: flex;
  flex-direction: column;
}
.mainImg{
  max-width: 30%;
  align-self: flex-start;
  border: 3px solid #a83242;
  border-radius: 50%;
}
.interests{
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
}
.interests img{
  width: 130px;
}
figure{
  margin: 20px 0;
}
h2{
  text-align: left;
  margin-left: 40px;
}
p{
  text-align: justify;
  margin-left: 40px;
}
@media screen and (max-width: 900px){
  .container{
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 0;
  }
  h2, p{
    margin-left: 0;
  }
  .mainImg{
    align-self: revert;
    max-width: 50%;
  }
  .interests{
    margin-left: 0;
  }
  .interests img{
    width: 50px;
  }
}
</style>