<template>
  <div id="experiences">
    <Heading :head="heading" :sub-text="subText"/>
    <div class="container">
      <div class="column">
        <h2>Education</h2>
        <ul>
          <li v-for="(element, index) in education" :key="index">
            <Experience :year="element.year"
                        :place="element.place"
                        :description="element.description"
            />
          </li>
        </ul>
      </div>
      <div class="column">
        <h2>Employment</h2>
        <ul>
          <li v-for="(element, index) in employment" :key="index">
            <Experience :year="element.year"
                        :place="element.place"
                        :description="element.description"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Heading";
import Experience from "@/components/Experience";

export default {
  name: "Experiences",
  components: {
    Heading,
    Experience
  },
  data() {
    return {
      heading: "01: Experience",
      subText: "Reliable | Self-imposed | Diligent",
      education: [
        {
          year: "2019 - present",
          place: "Metropolia University of Applied Sciences – Information and communication technologies",
          description:
              "Currently studying here. Studies are focused mainly on programming, but physics and mathematics are also " +
              "mandatory. We have covered the basics and some advanced concepts of object-oriented programming mainly " +
              "with Java and JavaScript. C and C# programming language also has been in use with games and robots. " +
              "Relational database MySQL and document database MongoDB have also been covered in our studies and used " +
              "in projects. From front-end side we have studied Vue and React."
        },
        {
          year: "2020",
          place: "edX courses for Metropolia – Online Courses",
          description:
              "Studied a couple of courses regarding AWS Cloud Security and Docker. In cloud security we studied " +
              "IAM privileges, VPC, Encryption, S3 Buckets and Secrets. In Docker class we studied a pipeline to work with " +
              "docker and manage the containerized app through CloudWatch, Beanstalk and many other Amazon services."
        },
        {
          year: "2019",
          place: "Töölön Aikuislukio – Complementary studies, college",
          description:
              "Studied on top of earlier college studies. Studies were focused on advanced mathematics, physics and " +
              "chemistry. Studies consisted mainly of chemistry for me and I managed studied all the mandatory classes."
        },
        {
          year: "2014 - 2017",
          place: "Helsingin Medialukio – Media line of studies, college",
          description:
              "Studied all mandatory courses to graduate as a secondary school graduate. I selected biology, intermediate " +
              "mathematics, English, Finnish and Russian for my matriculation examinations. I was also in a media line of " +
              "studies instead of a 'traditional' line of studies."
        }
      ],
      employment: [
        {
          year: "2021",
          place: "Sanoma Oyj (3 months)",
          description:
              "Worked as a team coding mainly front-end  with React and Tailwind. Git was used as the version control tool, " +
              "which I learned to use a lot (PR's, branching, etc.). Worked on many tickets which I picked from Jira " +
              "that were posted by designers. Got also to collaborate with said designers to improve Sanomas design system " +
              "a little bit regarding buttons and colors. Improved Sanomas new Supersää app by adding new components to the subsite."
        },
        {
          year: "2020",
          place: "AddSecure Smart Care Oy (6 months)",
          description:
              "Worked as a team to produce and ship safety phones to elderly people. The phones needed to be programmed, " +
              "linked to a system and cleaned before shipping. All phones were also tested before shipment."
        },
        {
          year: "2019",
          place: "Lammi-Perustus (3 months)",
          description:
              "Worked as a team to produce high-quality foundation for houses. The team was made up of four " +
              "workers and the production required at least two to operate the laminating machine, which I was assigned to."
        },
        {
          year: "2018",
          place: "Martinlaakso Health Center (1 year)",
          description:
              "Worked in an office storing patient information to a patient record system, billing patients, sending and " +
              "receiving mail and a general guide to all IT-related problems. My first task was to configure new phones " +
              "for every doctor and nurse in the center. I also configured many computers and phones which had problems."
        },
        {
          year: "2017",
          place: "Barona Logistics (6 months)",
          description:
              "Worked as a team packing and distributing provisions into crates and the crates on pallets."
        }
      ]
    }
  }
}
</script>

<style scoped>
#experiences {
  background-color: #080808;
  background-image: url("../assets/endless-clouds-black.svg");
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container{
  max-width: 1200px;
  line-height: 1.6;
  margin: 40px;

  display: flex;
  flex-wrap: wrap;
}
.column{
  flex: 50%;
  font-size: 16px;
}
ul li{
  list-style-type: none;
  padding-bottom: 20px;
}
@media screen and (max-width: 900px){
  .column{
    flex: 100%;
    font-size: 14px;
  }
}
@media screen and (max-width: 300px){
  .container{
    margin: 0;
  }
}
h2{
  border-bottom: 2px solid #a83242;
  display: inline-block;
  justify-content: left;
  margin-bottom: 20px;
  text-align: left;
}
</style>