<template>
  <div id="contact">
    <Heading :head="heading" :sub-text="subText" />
    <div class="container">
      <figure>
        <p id="alert">Email saved to clipboard</p>
        <img @click="copyEmail()" class="hoverEffect" src="../assets/gmailLogo.svg" alt="Logo of gmail" id="gmail">
        <figcaption id="email">eric.keranen98@gmail.com</figcaption>
      </figure>
      <figure>
        <img @click="toGit()" class="hoverEffect" src="../assets/githubLogo.svg" alt="Logo of GitHub">
        <figcaption>Mahamurahti / Eric</figcaption>
      </figure>
      <figure>
        <img @click="toLinkedIn()" class="hoverEffect" src="../assets/linkedinLogo.svg" alt="Logo of LinkedIn">
        <figcaption>Eric Keränen</figcaption>
      </figure>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Heading";

export default {
  name: "Contact",
  components: {
    Heading
  },
  data(){
    return{
      heading: "04: Contact",
      subText: "Click on the images to go to further links."
    }
  },
  methods: {
    copyEmail() {
      const el = document.createElement('textarea');
      el.value = 'eric.keranen98@gmail.com';
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      let hover = document.getElementById('gmail');
      hover.classList.remove('hoverEffect');

      let alert = document.getElementById('alert');
      alert.style.transform = 'translate(-50%, -50%) scale(1)';

      setTimeout(function(){
        alert.style.transform = 'translate(-50%, -50%) scale(0)';
        hover.classList.add('hoverEffect');
      }, 3000);
    },
    toGit() {
      window.open("https://github.com/Mahamurahti", "_blank");
    },
    toLinkedIn(){
      window.open("https://www.linkedin.com/in/eric-ker%C3%A4nen-40550a19a/", "_blank");
    }
  }
}
</script>

<style scoped>
#contact {
  background-color: #FAFAF5;
  background-image: url("../assets/endless-clouds-white.svg");
  background-size: cover;
  background-position: center;
  color: #080808;
}
.container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
p{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);

  width: 250px;
  height: 250px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  font-weight: bold;

  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);

  z-index: 2;

  transition: .2s ease-in-out;
}
img{
  width: 250px;
  height: 250px;
  border: 0 solid transparent;

  margin: 20px 40px 0 40px;

  transition: .2s ease-in-out;
}
.hoverEffect:hover{
  border: 5px solid #a83242;
  transform: translate(-10px, -10px);
  box-shadow: 10px 10px 10px #2e2d2d;
  cursor: pointer;
}
figure{
  position: relative;
  margin-bottom: 80px;
}
@media screen and (max-width: 400px){
  figure{
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 300px){
  img{
    width: 170px;
    height: 170px;
  }
}
</style>