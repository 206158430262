<template>
  <div id="skills">
    <Heading :head="heading" :sub-text="subText" />
    <div class="container">
      <div class="column">
        <h2>Programming</h2>
        <ul>
          <li v-for="(element, index) in programming" :key="index">
            <Skill :title="element.title" :exp="element.exp" :extras="element.extras"/>
          </li>
        </ul>
      </div>
      <div class="column">
        <h2>Software</h2>
        <ul>
          <li v-for="(element, index) in software" :key="index">
            <Skill :title="element.title" :exp="element.exp" :extras="element.extras"/>
          </li>
        </ul>
      </div>
      <div class="column">
        <h2>Libraries & Frameworks</h2>
        <ul>
          <li v-for="(element, index) in libraries" :key="index">
            <Skill :title="element.title" :exp="element.exp" :extras="element.extras"/>
          </li>
        </ul>
      </div>
      <div class="column">
        <h2>Languages</h2>
        <ul>
          <li v-for="(element, index) in languages" :key="index">
            <Skill :title="element.title" :exp="element.exp" :extras="element.extras"/>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Heading";
import Skill from "@/components/Skill";

export default {
  name: "Skills",
  components: {
    Heading,
    Skill
  },
  data() {
    return {
      heading: "02: Skills",
      subText: "Some skills have more info attached to them. Hover over the skill bar to see them.",
      programming: [
        {
          title: "Java",
          exp: 70,
          extras: [
            {
              title: "Objects",
              exp: 90
            },
            {
              title: "Classes",
              exp: 80
            },
            {
              title: "Inheritance",
              exp: 60
            },
            {
              title: "Polymorphism",
              exp: 60
            },
            {
              title: "Abstraction",
              exp: 30
            },
            {
              title: "Encapsulation",
              exp: 90
            }
          ]
        },
        {
          title: "JavaScript",
          exp: 65,
          extras: [
            {
              title: "Prototypes",
              exp: 40
            },
            {
              title: "Classes",
              exp: 55
            },
            {
              title: "Async/Await",
              exp: 40
            },
            {
              title: "REST API",
              exp: 30
            }
          ]
        },
        {
          title: "HTML + CSS",
          exp: 85,
          extras: [
            {
              title: "Semantics",
              exp: 80
            },
            {
              title: "Responsivity",
              exp: 85
            },
            {
              title: "Flexbox",
              exp: 70
            },
            {
              title: "Grid",
              exp: 50
            }
          ]
        },
        {
          title: "MySQL",
          exp: 50,
          extras: [
            {
              title: "Tables",
              exp: 30
            },
            {
              title: "Queries",
              exp: 60
            },
            {
              title: "Indexes",
              exp: 20
            },
            {
              title: "Clauses",
              exp: 20
            },
            {
              title: "Conditions",
              exp: 40
            }
          ]
        },
        {
          title: "C# + Unity",
          exp: 25,
          extras: [
            {
              title: "Objects",
              exp: 60
            },
            {
              title: "Classes",
              exp: 50
            },
            {
              title: "UI",
              exp: 30
            },
            {
              title: "Gameplay",
              exp: 35
            }
          ]
        },
        {
          title: "Python",
          exp: 10,
          extras: [
            {
              title: "Numpy",
              exp: 10
            },
            {
              title: "SciPy",
              exp: 5
            }
          ]
        }
      ],
      software: [
        {
          title: "Git",
          exp: 60,
          extras: [
            {
              title: "Git Desktop",
              exp: 60
            },
            {
              title: "Git Bash",
              exp: 40
            },
            {
              title: "Git Kraken",
              exp: 30
            }
          ]
        },
        {
          title: "IntelliJ IDEA",
          exp: 70
        },
        {
          title: "Visual Studio Code",
          exp: 40
        },
        {
          title: "Android Studio",
          exp: 30,
          extras: [
            {
              title: "Java",
              exp: 80
            },
            {
              title: "Kotlin",
              exp: 0
            }
          ]
        },
        {
          title: "Excel",
          exp: 50
        },
        {
          title: "PhotoShop",
          exp: 40
        }
      ],
      libraries: [
        {
          title: "Vue",
          exp: 40
        },
        {
          title: "React",
          exp: 55,
          extras: [
            {
              title: "Class comp.",
              exp: 5
            },
            {
              title: "Functional comp.",
              exp: 55
            }
          ]
        },
        {
          title: "Tailwind",
          exp: 70
        },
        {
          title: "SCSS",
          exp: 60
        }
      ],
      languages: [
        {
          title: "Finnish",
          exp: 80
        },
        {
          title: "English",
          exp: 85
        },
        {
          title: "Russian",
          exp: 20
        },
        {
          title: "Swedish",
          exp: 10
        }
      ]
    }
  }
}
</script>

<style scoped>
#skills {
  background-color: #FAFAF5;
  background-image: url("../assets/endless-clouds-white.svg");
  background-size: cover;
  background-position: center;
  color: #080808;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 16px;
}
.container{
  max-width: 1200px;
  line-height: 1.6;
  margin: 20px 40px 40px 40px;

  display: flex;
  flex-wrap: wrap;
}
p{
  max-width: 1200px;
  line-height: 1.6;
  margin: 0 40px;
}
.column{
  flex: 50%;
  font-size: 16px;
}
@media screen and (max-width: 900px){
  .column{
    flex: 100%;
    font-size: 14px;
  }
}
h2{
  border-bottom: 2px solid #a83242;
  display: inline-block;
  margin: 10px 0;
}
ul li{
  list-style-type: none;
  padding: 10px;
}
</style>