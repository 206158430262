<template>
  <div id="skill">
    <div class="row" @mouseenter="extraVisibility = !extraVisibility" @mouseleave="extraVisibility = !extraVisibility">
      <p>{{ title }}</p>
      <div class="level">
        <div :style="expStyle"></div>
      </div>
    </div>
    <transition name="fade">
      <div class="extra" v-if="extraVisibility">
        <ul>
          <li v-for="(element, index) in extras" :key="index">
            <Skill :title="element.title" :exp="element.exp" style="color: #FFFFFA"/>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Skill",
  data() {
    return {
      extraVisibility: false
    }
  },
  props: {
    title: String,
    exp: Number,
    extras: Array,
  },
  computed: {
    expStyle() {
      return {
        width: `${this.exp}%`,
        "max-width": `100%`,
        height: `100%`,
        "background-color": `white`
      }
    }
  }
}
</script>

<style scoped>
#skill{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}
.row{
  display: flex;
  flex-direction: row;
}
p{
  width: 150px;
  text-align: right;
}
.level{
  width: 300px;
  height: 25px;
  background-color: #1c1c1c;
  margin: 0 20px;
  outline: 2px solid #080808;

  transition: .2s ease-in-out;
}
.level:hover{
  transform: translateY(-5px) translateX(-5px);
  box-shadow: 5px 5px 5px #2e2d2d;
}
@media screen and (max-width: 500px){
  .level{
    width: 150px;
  }
}
@media screen and (max-width: 300px){
  p{
    width: 100px;
  }
  .level{
    width: 100px;
  }
}
ul li{
  list-style-type: none;
  padding: 10px 0;
}
.extra{
  background: linear-gradient(135deg, transparent 30px, #a83242 0) top left;
  position: absolute;
  top: 35px;

  z-index: 4;
  user-select: none;
}
.fade-enter-active, .fade-leave-active {
  transition: .2s ease-in-out;
}
.fade-enter, .fade-leave-to {
  transform: rotateX(90deg);
}

</style>