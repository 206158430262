<template>
  <div id="project">
    <div :class="positioning">
      <img @click="toGit()" :src="require('@/assets/' + img + '')" alt="Icon of project" title="Redirect to Git">
      <div class="column">
        <h1>{{ title }}</h1>
        <p>{{ description }}</p>
        <button @click="toGit" title="Redirect to Git">&lt;Source code&gt;</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  props: {
    title: String,
    img: String,
    positioning: String,
    description: String,
    link: String
  },
  methods: {
    toGit() {
      window.open(this.link, '_blank')
    }
  }
}
</script>

<style scoped>
#project{
  margin: 50px 0;
  position: relative;
  z-index: 1;
}
h1{
  border-bottom: 2px solid #a83242;
}
p{
  text-align: justify;
  margin-top: 10px;
  padding-right: 10px;
}
.left{
  display: flex;
  flex-direction: row;
}
.left .column{
  margin-left: 20px;
}
.right{
  display: flex;
  flex-direction: row-reverse;
}
.right .column{
  margin-right: 20px;
}
.column{
  display: flex;
  flex-direction: column;
  text-align: left;
}
img{
  width: 250px;
  height: 250px;
  border: 0 solid transparent;

  position: relative;
  background-color: transparent;

  transition: .2s ease-in-out;
}
img:hover{
  border: 5px solid #a83242;
  transform: translate(-10px, -10px);
  box-shadow: 10px 10px 10px #2e2d2d;
  cursor: pointer;
}
button{
  font-size: inherit;
  padding: 10px;
  background: transparent;
  background-size: 0;
  color: #a83242;
  border: none;
  outline: none;

  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 0% 50%);

  text-align: center;
  width: 150px;

  margin: 10px auto 0 auto;

  transition: .2s ease-in-out;
  display: none;
}
button:hover{
  background: white;
  background-size: 150px;

  color: #a83242;
  cursor: pointer;
}
@media screen and (max-width: 1100px){
  #project{
    margin: 20px 0;
  }
  #project:first-child{
    margin: 10px 0 20px 0;
  }
  img{
    display: none;
  }
  .right .column, .left .column{
    margin: 20px;
  }
  h1{
    font-size: 26px;
  }
  p{
    height: 200px;
    overflow-y: scroll;
  }
  button{
    display: inline-block;
  }
}
@media screen and (max-width: 400px){
  .right .column, .left .column{
    margin: 0;
  }
}
::-webkit-scrollbar{
  display: inline-block;
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #171616;
}
::-webkit-scrollbar-thumb {
  background: #a83242;
}
</style>