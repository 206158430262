<template>
  <div id="projects">
    <Heading :head="heading" :sub-text="subText" />
    <div class="container">
      <ul>
        <li v-for="(element, index) in projects" :key="index">
          <Project :title="element.title"
                   :img="element.img"
                   :positioning="element.positioning"
                   :description="element.description"
                   :link="element.link"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/Heading";
import Project from "@/components/Project";

export default {
  name: "Projects",
  components: {
    Heading,
    Project
  },
  data() {
    return {
      heading: "03: Projects",
      subText: "Click on the images to see the source code.",
      projects: [
        {
          title: "Space Themed Hangman Game",
          img: "blankHorizonsWhite.svg",
          positioning: "right",
          description:
              "A fullstack web project, where you can play a game of Hangman in a space themed environment. The app " +
              "was made with Next.js, Three.js, MySQL and Vercel. The database was provided by PlanetScale, which made " +
              "managing the database easier. Vercel was used for production deployment, which also made thing super " +
              "easy to deploy. In the app you can register a user to get an account where you can start storing your " +
              "earned scores and compete in a leaderboard. Authentication was done in the app via JSON Web Token (JWT). " +
              "Three.js library was used to render a 3D model of planet earth as the object you need to keep from " +
              "exploding by guessing words right. Earth keeps losing its segments if you guess letters wrong and finally " +
              "explode if you run out of guesses.",
          link: "https://github.com/Mahamurahti/Blank-Horizons"
        },
        {
          title: "Movie Finder",
          img: "fullstackProjectWhite.svg",
          positioning: "left",
          description:
              "A fullstack web project made for finding movies and series. You can register an account for the website " +
              "(only if you have the local database) and login to the account to access your \"Watch Later\" -list. By browsing the " +
              "main page or searching for movies / series you can now see a new button on the media you are looking for. " +
              "Clicking on the new plus button you can add the media to your own list. You can also check out information " +
              "about the media by clicking the image of the chosen media. You will also see the providers that are " +
              "available (currently only shows the providers for Finland). Media can also be deleted from your list. " +
              "You can also change your username, password and if you wish, also delete your account.",
          link: "https://github.com/Mahamurahti/MyMovie"
        },
        {
          title: "Health Center Simulator",
          img: "simulatorProjectWhite.svg",
          positioning: "right",
          description:
              "A simulator that simulates the on-going events in a health center. In the simulator patients " +
              "will go through a health center, passing the reception, an x-ray room or a doctors room depending on the " +
              "needs of the patient and lastly the checkout. Many different things will be calculated while the patient " +
              "is passing through the system: the number of patients that were in a service point, the max length of a " +
              "queue, the average length of a queue, the busy time of a service point and many more (see the code from " +
              "\"Tulokset.java\" for all the things that are calculated in the system). All information can be stored, " +
              "viewed and deleted to / from a database.",
          link: "https://github.com/Mahamurahti/Healthcenter-Simulator"
        },
        {
          title: "Hiking- / Nature Trail Finder",
          img: "websiteProjectWhite.svg",
          positioning: "left",
          description:
              "A website project made for finding hiking- / nature trails in Finland. You can also see a small nature gallery " +
              "with some of the pictures edited and a small video linked to the site. The website has two separate sections " +
              "depending if the user wants to go for a more relaxed nature trail just to enjoy nature or to a more physically " +
              "challenging hiking trail and maybe even stay a night on the trip. The website utilizes the APIs provided by " +
              "Lipas, OpenWeatherMaps and OpenStreetMaps.",
          link: "https://github.com/Mahamurahti/Caduceus"
        },
        {
          title: "Medicine Reminder",
          img: "androidProjectWhite.svg",
          positioning: "right",
          description:
              "An app for Android that can store medicine and remind the user to take the medicine in the time " +
              "the user has set for the medicine. The remind will kick off even when the app is closed. The medicine is " +
              "stored in a calendar and the user has to specify what medicine is stored on which day. When the remind " +
              "kicks off, the user can set the medicine as taken or not, depending if the user took the medicine. The " +
              "time will also be saved in the app to see when the user has taken the medicine.",
          link: "https://github.com/Mahamurahti/Medirem"
        },
        {
          title: "Robot Competition",
          img: "robotProjectWhite.svg",
          positioning: "left",
          description:
              "Source code for a robot competition held in Metropolia UAS on 13.12.2019. The source code contains the code " +
              "for three different competitions: maze solving, line following and sumo wrestling. " +
              "In maze solving the robot has to follow lines in a grid and avoid obstacles as well as get to the victory " +
              "line. All this had to be done without bumping into the obstacles and staying on the line. Our logic for " +
              "maze solving was the following: in every intersection the robot will turn left, if it can't turn left it " +
              "will go straight and if it can't turn left or go straight it will turn right. " +
              "In line following we just had to configure the robots turning speeds so that the robot survives the steep " +
              "turns. A lot of configuration went into this. " +
              "In sumo wrestling our strategy was to just to stay in the circle and if an enemy was detected, speed up " +
              "in an attempt to throw the other robot off the ring.",
          link: "https://github.com/Mahamurahti/Zumo-Robot"
        },
        {
          title: "2D Game Project",
          img: "gameProjectWhite.svg",
          positioning: "right",
          description:
              "A 2D game made with Unity and C#. The goal was to make a Mario-style game with added combat. The story of the " +
              "game revolves around Thor collecting his lost divine powers back. The games name is based of a story with " +
              "the same name. In this game you will encounter enemies which you can fight or skip, depending on what you want " +
              "to do. Features of the game include, but is not limited to: fully functioning attacking and health systems, " +
              "enemy patrol system and counterattacking, parallax background and cheats. All of the art was also done by hand.",
          link: "https://github.com/Mahamurahti/Journey-To-Utgard"
        }
      ]
    }
  }
}
</script>

<style scoped>
#projects {
  background-color: #080808;
  background-image: url("../assets/endless-clouds-black.svg");
  background-position: center;
  background-size: cover;
  color: #FFFFFA;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 16px;
}
.container{
  max-width: 1200px;
  margin: 0 40px;
  line-height: 1.6;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
ul li{
  list-style-type: none;
}
</style>