<template>
  <div id="app">
    <Welcome/>
    <Profile/>
    <Experience/>
    <Skills/>
    <Projects/>
    <Contact/>
  </div>
</template>

<script>
import Welcome from "@/components/Welcome";
import Profile from "@/components/Profile";
import Experience from "@/components/Experiences";
import Skills from "@/components/Skills";
import Projects from "@/components/Projects";
import Contact from "@/components/Contact";

export default {
  name: 'App',
  components: {
    Welcome,
    Profile,
    Experience,
    Skills,
    Projects,
    Contact
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  text-align: center;
  color: white;

  background-color: #19181a;
}

html{
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

</style>
