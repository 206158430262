<template>
  <div id="experience">
    <h3>
      <span>{{ year }}</span> <br>
      {{ place }}
    </h3>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "Experience",
  props: {
    year: String,
    place: String,
    description: String,
  }
}
</script>

<style scoped>
#experience{
  position: relative;
  padding: 10px;
}
@media screen and (max-width: 400px){
  #experience{
    padding: 0;
  }
  p{
    text-align: left;
  }
}
@media screen and (max-width: 300px) {
  p {
    display: none;
  }
}
h3{
  text-align: left;
  padding-bottom: 10px;
}
p{
  text-align: justify;
}
span{
  border-bottom: 1px solid #a83242;
}
</style>