<template>
  <div class="heading">
    <h1>{{ head }}</h1>
    <h3>{{ subHead }}</h3>
    <p>{{ subText }}</p>
  </div>
</template>

<script>
export default {
  name: "Heading",
  props: {
    head: String,
    subHead: String,
    subText: String
  }
}
</script>

<style scoped>
.heading{
  padding-top: 40px;
  font-size: 24px;

  transition: .2s ease-in-out;
}
p{
  font-size: 16px;
  margin: 0 60px;
  padding-top: 20px;
}
@media screen and (max-width: 400px){
  .heading{
    font-size: 18px;
  }
}
</style>