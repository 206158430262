<template>
  <div id="welcome">
    <div class="scanlines"></div>
    <div class="wrap">
      <h1 id="name">
        <div class="letter" data-text="E" data-alt="3"/>ric Ke<div class="letter" data-text="r" data-alt="я"/>äne<div class="letter" data-text="n" data-alt="и"/>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  methods: {
    animState() {
      let elHeight = document.getElementById('welcome').scrollHeight;
      let scrollHeight = window.scrollY;
      if(scrollHeight > elHeight){
        document.getElementById('name').style.animationPlayState = 'paused';
      }else{
        document.getElementById('name').style.animationPlayState = 'running';
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.animState);
  }
}
</script>

<style scoped>
#welcome {
  background-color: #080808;
  background-image: url("../assets/circuit-board.svg");
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;
}
.scanlines{
  position: absolute;

  width: inherit;
  height: inherit;

  background-image: repeating-linear-gradient(-60deg, transparent, transparent 2px, rgba(0,0,0,.5) 3px, rgba(0,0,0,.5) 3px, transparent 4px);
  background-size: cover;
}
.wrap{
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: inherit;
}
h1 {
  position: absolute;
  width: 100vw;
  max-width: 100vw;

  font-weight: bold;
  font-size: 8rem;
  letter-spacing: 10px;
  user-select: none;

  animation: glitch-color 7s infinite alternate, glitch-pos 9s infinite;
}
.letter{
  display: inline;
}
.letter:after{
  content: attr(data-text);
}
.letter:nth-child(1):after{
  animation: glitch-text 6s infinite;
}
.letter:nth-child(2):after{
  animation: glitch-text 8s infinite;
}
.letter:nth-child(3):after{
  animation: glitch-text 7s infinite;
}
@media screen and (max-width: 1030px) {
  h1 {
    font-size: 3rem;
  }
}
@keyframes glitch-text {
  0%{
    content: attr(data-text);
  }
  1%{
    content: attr(data-alt);
  }
  20%{
    content: attr(data-text);
  }
  100%{
    content: attr(data-text);
  }
}
@keyframes glitch-pos {
  1%{
    transform: rotateX(10deg) skewX(90deg) scale(2);
  }
  5%{
    transform: rotateX(0deg) skewX(0deg) scale(1);
  }
  20%{
    transform: translate(0, 0);
  }
  23%{
    transform: translateY(30px);
  }
  23.1%{
    transform: translate(0, 0);
  }
  45%{
    transform: translate(0, 0);
  }
  47%{
    transform: translate(-30px, -40px);
  }
  47.1%{
    transform: translate(0, 0);
  }
  50%{
    transform: scale(1);
  }
  50.5%{
    transform: scale(1.8);
  }
  51%{
    transform: scale(1);
  }
  70%{
    transform: translate(0, 0);
  }
  70.5%{
    transform: translate(20px, -20px) scale(.6);
  }
  71%{
    transform: translate(0, 0) scale(1);
  }
}
@keyframes glitch-color {
  1%{
    text-shadow: -10px 0 violet, 2px 0 lightgreen;
  }
  1.5%{
    text-shadow: 0 0 violet, 5px 0 lightgreen;
  }
  2%{
    text-shadow: -10px 0 violet, 2px 0 lightgreen;
    transform: skewX(10deg) scale(1.5);
  }
  2.5%{
    text-shadow: -10px 0 violet, 5px 0 lightgreen;
    transform: skewX(0deg) scale(1);
  }
  75%{
    text-shadow: 0 0 violet, 2px 0 lightgreen;
  }
  75.5%{
    text-shadow: 0 -10px violet, 0 5px lightgreen;
  }
  76%{
    text-shadow: -10px 0 violet, 5px 0 lightgreen;
  }
  76.5%{
    text-shadow: 0 0 violet, 5px 0 lightgreen;
  }
}
</style>